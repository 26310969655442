<template>
  <div class="f-container">
    <div
      class="page-outter"
      style="width: 60%"
    >
      <div class="ff-outter">
        <div class="d1">
          <img
            src="./images/jyw.png"
            style="width: 194px"
          />
          <span>九为（浙江）网络科技有限公司</span>
          <div style="width:100%;">
            <div style="float:left;width:100px">
              <img
                src="./images/emmgz.png"
                style="margin-top: 20px;"
              />
              <div style="width:100%;text-align:center;font-size:14px;color:#fff;margin-top:10px">了解更多</div>
            </div>
            <div style="float:left;width:100px;margin-left:10px;">
              <img
                src="./images/kfts2.png"
                style="margin-top: 20px;width:100px;"
              />
              <div style="width:100%;text-align:center;font-size:14px;color:#fff;margin-top:10px">我要反馈</div>
            </div>
          </div>

        </div>
        <div class="d2">
          <span class="s1">联系我们</span>
          <span class="s2">400-860-1033</span>
          <span class="s3">宁波市镇海区骆驼街道民和路798号</span>
          <span class="s3">jiuyy@91medicine.cn</span>
          <span
            class="s4"
            @click="goSettle"
          >客户入驻审核规范</span>
        </div>
      </div>
      <div class="t-s-footer">
        <div><span @click="showImg('zzdxywjy')">增值电信业务经营许可证：浙B2-20210189 ｜ </span><span @click="showImg('wljyxkz')">网络文化经营许可证：浙网文(2020)5940-260号 ｜ </span><span @click="showImg('hlwzgzs')">互联网药品信息服务资格证：(浙)-经营性-2020-0061</span></div>
        <div style="margin-top: 10px"><span @click="showImg('yyzz')">营业执照：91330211MA2H46DFX5 ｜ </span><span @click="showImg('ylqx')">医疗器械网络交易服务第三方平台备案凭证：（浙）网械平台备字[2021]第00012号</span></div>
        <div style="margin-top: 10px" ><span @click="goIcp">ICP备案编号：浙ICP备20015044号</span> ｜ <span style="cursor:default">copyright &#169 2022 九为（浙江）网络科技有限公司 All Rights Reserved</span></div>
      </div>
      <el-dialog
        :visible.sync="dialogVisible"
        width="700px"
      >
        <img
          width="100%"
          :src="srcImg"
        />
      </el-dialog>
    </div>
  </div>

</template>

<script>
export default {
  name: "navFooter",
  data() {
    return {
      dialogVisible: false,
      srcImg: "",
    };
  },
  methods: {
    goSettle() {
      this.$router.push("/settlement");
    },
    showImg(name) {
      this.srcImg = require(`@/views/aboutUs/components/images/${name}.jpg`);
      this.dialogVisible = true;
    },
    goIcp() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index");
    },
  },
};
</script>

<style lang="less" scoped>
.f-container {
  width: 100%;
  background: #4a4a4a;
  border-top: 1px solid #4a4a4a;
  border-bottom: 1px solid #4a4a4a;
}
.ff-outter {
  display: flex;
  justify-content: space-between;
  .d1 {
    padding-top: 60px;
    span {
      display: block;
      color: #fff;
      margin-top: 10px;
      font-size: 14px;
    }
    img {
      display: block;
    }
  }
  .d2 {
    padding-top: 60px;
    text-align: right;
    span {
      color: #fff;
      display: block;
    }
    .s1 {
      font-size: 22px;
    }
    .s2 {
      font-size: 34px;
      font-weight: 600;
      margin-top: 20px;
    }
    .s3 {
      font-size: 14px;
      margin-top: 20px;
    }
    .s4 {
      font-size: 15px;
      margin-top: 20px;
      cursor: pointer;
      &:hover {
        color: #479bff;
      }
    }
  }
}
.t-s-footer {
  text-align: center;
  font-size: 0.3rem;
  color: #dcdadd;
  margin-bottom: 0.8rem;
  margin-top: 36px;
  span {
    cursor: pointer;
  }
}
</style>